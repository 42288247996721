<template>
  <div class="row">
    <Form
      action=""
      class="form form_col"
      autocomplete="off"
      @submit="handleCustomer"
    >
      <div class="col-12">
        <h3>Order reference</h3>
        <label class="form__label">
          <Field
            v-model="reference"
            :rules="'max: 255'"
            type="text"
            class="form__input"
            placeholder="Reference"
            name="Reference"
          />
            <ErrorMessage class="form__error"  name="Reference" />
        </label>

      </div>

      <div class="col-12 col-md-6">
        <h3>New Customer</h3>

        <label class="form__label">
          <span class="form__labelTitle">Name*</span>
          <Field
            v-model="customerDetails.name"
            :rules="'required|max: 50'"
            type="text"
            class="form__input"
            placeholder="Name"
            name="name"
            :disabled="disableInputs"
          />
          <ErrorMessage class="form__error"  name="name" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Email*</span>
          <Field
            v-model="customerDetails.email"
            :rules="'required|email|max:50'"
            type="text"
            class="form__input"
            placeholder="Email"
            name="email"
            :disabled="disableInputs"
          />
          <ErrorMessage class="form__error"  name="email" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Phone</span>
          <Field
            v-model="customerDetails.phone"
            :rules="{digits_without_whitespace:maxValue}"
            class="form__input"
            v-maska="'## ## ### ###'"
            type="text"
            name="phone"
            placeholder="Mobile Number"
            :disabled="disableInputs"
          />
          <ErrorMessage class="form__error"  name="phone" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Address*</span>
          <Field
            v-model="customerDetails.address"
            :rules="'required|max: 255'"
            type="text"
            class="form__input"
            placeholder="Address"
            name="address"
            :disabled="disableInputs"
          />
          <ErrorMessage class="form__error"  name="address" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Suburb</span>
          <Field
            v-model="customerDetails.suburb"
            :rules="'max: 255'"
            type="text"
            class="form__input"
            placeholder="Suburb"
            name="suburb"
            :disabled="disableInputs"
          />
          <ErrorMessage class="form__error"  name="suburb" />
        </label>
        <div class="row">
          <div class="col-6">
            <label class="">
              <span class="form__labelTitle">State</span>
              <Field
                as="select"
                v-model="customerDetails.state_id"
                class="form__input"
                name="state"
                :style="opacity(customerDetails.state_id)"
                :disabled="disableInputs"
              >
                <option
                  value=""
                  selected
                  disabled
                  hidden
                >State</option>
                <option
                  v-for="item in state"
                  :key="item.id"
                  :value="item.state_id"
                >{{ item.name }}
                </option>
              </Field>
              <ErrorMessage class="form__error"  name="state" />
            </label>
          </div>
          <div class="col-6">
            <label class="">
              <span class="form__labelTitle">Postcode</span>
              <Field
                v-model="customerDetails.postcode"
                :rules="{required: false, digits: 4}"
                type="text"
                class="form__input"
                placeholder="Postcode"
                name="postcode"
                :disabled="disableInputs"
              />
              <ErrorMessage class="form__error"  name="postcode" />
            </label>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 h-md-100">
        <h3>Your Customers</h3>

        <input
                placeholder="Type to search"
                v-model="cust_search"
                class="form__input"
/>

        <label class="form__label customer_list" v-if="existingCustomer && existingCustomer.length > 0">

          <div
            v-for="(item, index) in existingCustomer"
            class="customer"
            @click="selectCustomer(item)"
          >
            {{ item.name }} <span v-if="item.phone">- {{ item.phone }}</span> - {{ item.email }}
            <br><span class="text_small">{{ item.address }}</span>
          </div>
        </label>
        <div v-else>You have not created any customers yet.</div>

      </div>

      <div class="col-12">
        <br>
      </div>

      <div
        v-if="!modal"
        class="form__row col-12 form__row_full form__row_margin form__row_start"
      >
        <button
          class="btn_default btn"
          type="submit"
        ><svg-icon name="solid/plus" />
          Create Order
        </button>
        <button
          class="btn_default btn"
          type="button"
          @click="withoutCustomer"
        ><svg-icon name="solid/user-times" />
          Proceed without customer
        </button>
        <button
          class="btn_default btn"
          type="button"
          @click="cancel"
        ><svg-icon name="solid/times" />
          Cancel
        </button>
      </div>
      <div
        v-else
        class="form__row form__row_full form__row_margin form__row_start"
        :class="{'form__row_absolute': this.modal}"
      >
        <button
          class="btn_transparent btn"
          type="submit"
        ><svg-icon name="solid/floppy-disk"></svg-icon>
          Save
        </button>
        <button
          class="btn_transparent btn"
          type="button"
          @click="closeWindow"
        ><svg-icon name="solid/times" />
          Cancel
        </button>
      </div>
    </Form>
  </div>
</template>

<script>
import router from '../../../router'
import {httpReq} from '@/htttpReq'
import { maska } from 'maska'

import {mapGetters, mapState} from 'vuex'

export default {
    name: 'CustomerDetails',
    directives: {
        maska
    },
    props: {
        modal: Boolean
    },
    data() {
        return {
            customerDetails: {
                address: '',
                email: '',
                customer_id: '',
                name: '',
                phone: '',
                postcode: '',
                state_id: '',
                suburb: '',
                user_id: '',
                is_guest: 0,
                order_id: localStorage.getItem('order_id') || '',
            },
            cust_search: '',
            disableExist: false,
            disableInputs: false,
            reference: null
        }
    },

    computed: {
        ...mapGetters({
          items: 'orderPreview/items',
        }),
        existingCustomer() {

            let allCustomers  = this.$store.getters['customerDetails/existingCustomer']

            if (!this.cust_search || this.cust_search == '')
                return allCustomers

            return allCustomers.filter(i => {

                let found = i.name.toLowerCase().indexOf(this.cust_search.toLowerCase()) != -1 ||
                    i.address.toLowerCase().indexOf(this.cust_search.toLowerCase()) != -1

                if (i.email)
                    found = found || i.email.toLowerCase().indexOf(this.cust_search.toLowerCase()) != -1

                if (i.phone)
                    found = found || i.phone.toLowerCase().indexOf(this.cust_search.toLowerCase()) != -1


                return found
            })

        },


        state() {
            return this.$store.getters['state/state']
        },

        maxValue() {
            if (this.customerDetails.phone && this.customerDetails.phone.length <= 6) {
                return 6
            }
            else return 10
        }
    },

    watch: {
        'customerDetails.customer_id'() {
            if (this.customerDetails.customer_id === '') {
                for (let k in this.customerDetails) {
                    this.customerDetails[k] = ''
                    this.customerDetails.order_id = localStorage.getItem('order_id') || ''
                    if (k === 'is_guest') {
                        this.customerDetails[k] = 0
                    }
                }
            }
        },
    },

    mounted() {
      this.reference = this.items.reference
        return this.$store.dispatch('customerDetails/getExistingCustomer')
    },

    beforeUpdate() {
        this.checkFields()
    },

    created() {
        this.addOwner()
      this.reference = null
        return this.removeUserId()
    },

    methods: {


        selectCustomer(item){
            this.customerDetails.address = item.address
            this.customerDetails.email = item.email
            this.customerDetails.customer_id = item.id
            this.customerDetails.name = item.name
            this.customerDetails.phone = item.phone
            this.customerDetails.postcode = item.postcode
            this.customerDetails.state_id = item.state_id
            this.customerDetails.suburb = item.suburb
            this.handleCustomer()
        },

        handleCustomer() {
          /*  this.$validator.validate().then(valid => {
                if (valid) {*/
                    if (!this.modal) {
                        this.$store.dispatch('customerDetails/sendData', {customerDetails: this.customerDetails, reference: this.reference})
                    }
                    else {
                        this.$store.dispatch('customerDetails/customerDetailsToOrder', {customerDetails: this.customerDetails, reference: this.reference})
                    }
              /*  }
            })*/
        },

        cancel() {
            router.push({name: 'orderList'})
        },

        withoutCustomer() {
            this.$store.commit('orderPreview/clearItems')
            this.customerDetails.is_guest = 1
            this.$store.dispatch('customerDetails/sendData', {customerDetails: this.customerDetails, reference: this.reference})
        },

        checkFields() {
            for (let key in this.customerDetails) {
                if (this.customerDetails[key] !== '') {
                    return this.disableExist = false
                }
                return this.disableExist = false
            }
        },

        checkFieldsSelect() {
            if (this.customerDetails.customer_id !== '') {
                let id = this.customerDetails.customer_id
                let user = _.find(this.existingCustomer, function (el) {
                    return el.id === id
                })
                this.customerDetails.name = user.name
                this.customerDetails.email = user.email
                this.customerDetails.phone = user.phone
                this.customerDetails.address = user.address
                this.customerDetails.suburb = user.suburb
                this.customerDetails.postcode = user.postcode
                this.customerDetails.email = user.email
                this.customerDetails.state_id = user.state_id

                return this.disableInputs = true
            }
            else {
                return this.disableInputs = false
            }

        },

        removeUserId() {
            if (!this.modal) {
                localStorage.removeItem('order_id')
                localStorage.removeItem('order_url')
            }
        },

        opacity(elem) {
            if (!elem) {
                return 'color: #B5B5B5'
            }
        },

        closeWindow() {
          this.reference = null
            this.$store.commit('customerDetails/customerDetailsOpen', false)
        },

        addOwner() {
            let customer = this.$store.getters['orderPreview/items'].customer
            if (customer !== null && this.$route.name !== 'customer-details') {
                this.customerDetails.name = customer.name
                this.customerDetails.email = customer.email
                this.customerDetails.phone = customer.phone
                this.customerDetails.address = customer.address
                this.customerDetails.suburb = customer.suburb
                this.customerDetails.postcode = customer.postcode
                this.customerDetails.email = customer.email
                this.customerDetails.state_id = customer.state
                this.customerDetails.customer_id = customer.id
                //return this.disableInputs = true
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/utils/vars";

    .form {

        width: 100%;

        &__input {
            border-color: $navy !important;
        }
        &__labelTitle {
            color: $navy;
        }

        &__row_absolute {
            position: absolute;
            z-index: 1;
            left: 2rem;
            right: 2rem;
            bottom: 20px;
            width: auto;
        }
    }

    .customer_list{
      max-height: 50vh;
      overflow-y: scroll;
    }

    .customer{
        padding: 5px 0;
        cursor: pointer;
    }

    .customer:nth-child(odd){
        background-color: #ddd;
    }



</style>
